import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import BEMHelper from "react-bem-helper";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";
import HubspotForm from "../components/hubsport-form";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

const bem = new BEMHelper({
  name: "template-contact",
});

const Contact = ({ data, location, pageContext }) => {
  const page = data.contentfulTemplateContactPage;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

    useEffect(() => {
        const obj = {
            url: location.href,
            contentType: "contact",
            pageType: "section",
            pageTitle: 'Contact Us',
            sbu: "ULX",
            form: "yes",
            formId: page.contactPageForm.formId,
            contentPublicationDate: "2022-03-18T15:23:44"
        };
        window?.dataLayer?.push(obj);
    }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const metaImage = page.seoShareImage ? page.seoShareImage.file.url : null;
  const metaTitle = page?.seoPageTitle || page?.pageTitle;

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={
          page.pageDescription?.pageDescription ||
          "Let's connect! Learn more about how UnitedLex can help solve your legal and business challenges or sign up to receive our thought leadership today."
        }
        image={metaImage}
        url={"https://www.unitedlex.com/"+ page.slug}
      />
      <HeaderSolidColor
        headerTitle={page?.pageTitle || null}
        headerDescription={page?.pageDescription?.pageDescription || null}
      />
      <div {...bem()}>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {page.contactPageForm && (
                <HubspotForm
                  // ref={formRef}
                  portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
                  formId={page.contactPageForm.formId}
                  onSubmit={() => console.log("Submit!")}
                  onReady={(form) => {
                    console.log("Form ready: ", form);
                  }}
                  loading={<div>Loading...</div>}
                />
              )}
            </div>
          </article>
          <aside {...bem("aside")}>
            {page.sideBox1 && (
              <div {...bem("side-box")}>
                <span {...bem("visual-hairline")} />
                <div {...bem("side-box-copy")}>
                  {documentToReactComponents(
                   JSON.parse(page.sideBox1.raw),
                    richTextConfig()
                  )}
                </div>
              </div>
            )}

            {page.sideBox2 && (
              <div {...bem("side-box")}>
                <span {...bem("visual-hairline")} />
                <div {...bem("side-box-copy")}>
                  {documentToReactComponents(
                    JSON.parse(page.sideBox2.raw),
                    richTextConfig()
                  )}
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateContactPage(slug: { eq: $slug }) {
      pageTitle
      doNotIndex
      pageDescription {
        pageDescription
      }
      seoPageTitle
      seoShareImage {
        ...ContentfulAssetFragment
      }
      slug
      contactPageForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
      }
      sideBox1 {
        raw
      }
      sideBox2 {
        raw
      }
    }
  }
`;
